import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Axios from "axios";
import { HeaderContext } from "../Includes/HeaderContext";

export default function NewsSingle() {
	const [SingleNewsResponse, setSingleNewsResponse] = useState([]);
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState();
	let { slug } = useParams();
	const { LangData } = useContext(HeaderContext);
	const history = useHistory();

	const handleBack = () => {
		history.goBack();
	};

	let getUrl =
		"https://kapilvastuhospital.p5.gov.np/api/public/api/news/" +
		slug +
		"/" +
		LangData[0];

	useEffect(() => {
		let source = Axios.CancelToken.source();
		const loadData = async () => {
			try {
				const response = await Axios.get(
					getUrl,
					{
						timeout: 10000,
					},
					{
						cancelToken: source.token,
					}
				);
				setSingleNewsResponse(response.data.data);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
		return () => {
			source.cancel();
		};
	}, [getUrl]);
	console.log(SingleNewsResponse);

	return (
		<React.Fragment>
			<div className="back-button">
				<a href={(e) => e.preventDefault()} onClick={handleBack}>
					<i className="fa fa-arrow-left"></i>
				</a>
			</div>
			<div className="news-wrapper">
				<div className="img-container">
					<img
						src={SingleNewsResponse.image}
						alt={SingleNewsResponse.title}
						className="img-fluid"
					/>
				</div>
				<div className="title">
					<strong>{SingleNewsResponse.title}</strong>
				</div>
				<div
					className="long-description"
					dangerouslySetInnerHTML={
						SingleNewsResponse && { __html: SingleNewsResponse.description }
					}
				></div>
				<div className="date">{SingleNewsResponse.publishedDate}</div>
			</div>
		</React.Fragment>
	);
}
