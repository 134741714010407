import React, {useState, useEffect, useContext} from "react";
import Axios from "axios";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { HeaderContext } from "../Includes/HeaderContext";
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/Defaultlang";
import LanguageProvider from "../Lang/LanguageProvider";

export default function RecentNotice(){
	const [NoticeResponse, setNoticeResponse] = useState([]);
	const [err, setErr] = useState();
	const {LangData} = useContext(HeaderContext);
	

	let getUrl =
    "https://kapilvastuhospital.p5.gov.np/api/public/api/newseventnotice/" +
    LangData[0];

        useEffect(() => {
            let source = Axios.CancelToken.source();
            const loadData = async () => {
                try {
                    const response = await Axios.get(
                        getUrl,
                        {
                            cancelToken: source.token
                        }
                    );
                    setNoticeResponse(response.data.data.notices);
                } catch (error) {
                    if (Axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        setErr(() => {
                            throw error;
                        });
                    }
                }
            };
            loadData();
            window.scrollTo(0, 0);
            return () => {
                source.cancel();
            };
        }, [getUrl]);
        console.log(NoticeResponse);
    return(
        <React.Fragment>
                                <LanguageProvider>
                                <div className="sidebar-title">
										<FormattedMessage
											id="recent_notice"
											defaultMessage={defineMessages.pages.recent_notice}
										/>
									</div>
								</LanguageProvider>
                            
								<ul>
                                    {
                                        NoticeResponse &&
                                        NoticeResponse.map((notice,index)=>
                                        <li key={notice.id}>
                                            <Link to={`/notice/${notice.id}`}>{notice.title}</Link>
                                        </li>
                                        )
                                    }
									
                                   
								</ul>
                               
            </React.Fragment>
    )
}