import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import { HeaderContext } from "../Includes/HeaderContext";
import { useLocalStorage } from "../LocalStorageHook";

export default function Vision() {
	const [MissionResponse, setMissionResponse] = useLocalStorage(
		"MissionResponse"
	);
	const [VisionResponse, setVisionResponse] = useState("VisionResponse");

	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState();
	const { LangData } = useContext(HeaderContext);

	let getUrl =
		"https://kapilvastuhospital.p5.gov.np/api/public/api/vision-mission/" +
		LangData[0];

	useEffect(() => {
		let source = Axios.CancelToken.source();
		const loadData = async () => {
			try {
				const response = await Axios.get(
					getUrl,
					{
						timeout: 10000,
					},
					{
						cancelToken: source.token,
					}
				);
				setMissionResponse(response.data.data[0]);
				setVisionResponse(response.data.data[1]);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		return () => {
			source.cancel();
		};
	}, [getUrl]);
	console.log(VisionResponse);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<React.Fragment>
			<div className="mission-container">
				{MissionResponse && (
					<div className="container">
						<div className="mission-title">{MissionResponse.title}</div>
						<div className="row">
							<div className="col-md-8">
								<div
									className="mission-detail"
									dangerouslySetInnerHTML={{
										__html: MissionResponse.description,
									}}
								></div>
							</div>
							<div className="col-md-4">
								<div className="img-container">
									<img
										src={MissionResponse.image}
										alt={MissionResponse.title}
										className="img-fluid"
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="vision-container">
				{VisionResponse && (
					<div className="container">
						<div className="vision-title">{VisionResponse.title}</div>
						<div className="row">
							<div className="col-md-8">
								<div className="vision-detail">
									<p
										dangerouslySetInnerHTML={{
											__html: VisionResponse.description,
										}}
									></p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="img-container">
									<img
										src={VisionResponse.image}
										alt={VisionResponse.title}
										className="img-fluid"
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</React.Fragment>
	);
}
