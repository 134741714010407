import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { HeaderContext } from "../Includes/HeaderContext";

export default function Disclaimer() {
	const [PrivacyResponse, setPrivacyResponse] = useState([]);
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState();
	const { LangData } = useContext(HeaderContext);

	let getUrl = "https://kapilvastuhospital.p5.gov.np/api/public/api/pages/" + LangData[0];

	useEffect(() => {
		let source = Axios.CancelToken.source();
		const loadData = async () => {
			try {
				const response = await Axios.get(
					getUrl,
					{
						timeout: 10000,
					},
					{
						cancelToken: source.token,
					}
				);
				setPrivacyResponse(response.data.data[1]);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
		return () => {
			source.cancel();
		};
	}, [getUrl]);
	console.log(PrivacyResponse);

	return (
		<div className="privacy container text-justify my-5">
			<h3>{PrivacyResponse.title}</h3>
			<p className="mt-4">{PrivacyResponse.content}</p>
		</div>
	);
}
