import React , {useState, useEffect, useContext} from "react";
import {useParams, useHistory} from "react-router-dom";
import Axios from 'axios';
import { HeaderContext } from "../Includes/HeaderContext";


export default function EventsSingle() {
	const [SingleEventResponse, setSingleEventResponse] = useState([]);
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState();
	let {slug} = useParams();
	const {LangData} = useContext(HeaderContext);
	const history = useHistory();

	const handleBack = ()=>{
		history.goBack();
	}

	let getUrl =
		"https://kapilvastuhospital.p5.gov.np/api/public/api/events/" + slug + "/" + LangData[0];

	useEffect(() => {
		let source = Axios.CancelToken.source();
		const loadData = async () => {
			try {
				const response = await Axios.get(
					getUrl,
					{
						timeout: 10000
					},
					{
						cancelToken: source.token
					}
				);
				setSingleEventResponse(response.data.data);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
		return () => {
			source.cancel();
		};
	}, [getUrl]);
	console.log(SingleEventResponse);
	return (
		<React.Fragment>
			<div className="back-button">
					<a href={e=>e.preventDefault()} onClick={handleBack}><i className="fa fa-arrow-left"></i></a>
				</div>
			<div className="news-wrapper">
				<div className="img-container">
					<img
						src={SingleEventResponse.image}
						alt={SingleEventResponse.title}
						className="img-fluid"
					/>
				</div>
				<div className="title">
					<strong>{SingleEventResponse.title}</strong>
				</div>
				<div className="long-description" dangerouslySetInnerHTML={
										SingleEventResponse && { __html: SingleEventResponse.long}
									}>
					
				</div>
                <div className="date">
                    
						{SingleEventResponse.publishedDate}
					                </div>
			</div>
		</React.Fragment>
	);
}
