import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import { HeaderContext } from "../Includes/HeaderContext";
import { useLocalStorage } from "../LocalStorageHook";

export default function About() {
	const [AboutResponse, setAboutResponse] = useLocalStorage("aboutResponse");
	const [err, setErr] = useState();
	const { LangData } = useContext(HeaderContext);

	let getUrl =
    "https://kapilvastuhospital.p5.gov.np/api/public/api/about/" + LangData[0];

	useEffect(() => {
		let source = Axios.CancelToken.source();
		const loadData = async () => {
			try {
				const response = await Axios.get(
					getUrl,
					{
						timeout: 10000,
					},
					{
						cancelToken: source.token,
					}
				);
				setAboutResponse(response.data.data[0]);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		return () => {
			source.cancel();
		};
	}, [getUrl]);
	console.log(AboutResponse);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<React.Fragment>
			<div className="about-section">
				<div className="container">
					{AboutResponse && (
						<div className="row">
							<div className="col-md-7">
								<div className="about-title">
									<h3>{AboutResponse && AboutResponse.title}</h3>
								</div>
								<div
									className="about-short"
									dangerouslySetInnerHTML={
										AboutResponse && { __html: AboutResponse.long }
									}
								></div>
							</div>
							<div className="col-md-5">
								<div className="img-container">
									<img
										src={AboutResponse.image}
										alt={AboutResponse.title}
										className="img-fluid"
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
}
