import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, Link } from "react-router-dom";
import EventsSingle from "./EventsSingle";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/Defaultlang";
import RecentNotice from "../Component/RecentNotice";
import RecentNews from "../Component/RecentNews";
import { HeaderContext } from "../Includes/HeaderContext";

export default function Events() {
	const [EventResponse, setEventResponse] = useState([]);
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState();
	const { LangData } = useContext(HeaderContext);
	const [pagination, setPagination] = useState([]);

	let getUrl =
		"https://kapilvastuhospital.p5.gov.np/api/public/api/events/" + LangData[0];

	useEffect(() => {
		let source = Axios.CancelToken.source();
		const loadData = async () => {
			try {
				const response = await Axios.get(
					getUrl,
					{
						timeout: 10000,
					},
					{
						cancelToken: source.token,
					}
				);
				setEventResponse(response.data);
				setPagination(response.data.meta);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
		return () => {
			source.cancel();
		};
	}, [getUrl]);

	const PaginateData = async (PageNumber) => {
		try {
			const response = await Axios.get(
				`https://kapilvastuhospital.p5.gov.np/api/public/api/events/${LangData[0]}?page=${PageNumber}`,
				{
					timeout: 10000,
				}
			);
			setEventResponse(response.data);
			setPagination(response.data.meta);
		} catch (error) {
			if (Axios.isCancel(error)) {
				console.log(error);
			} else {
				setErr(() => {
					throw error;
				});
			}
		}
		window.scrollTo(0, 0);
	};
	const SetPage = async (link) => {
		if (link != null) {
			try {
				const response = await Axios.get(link, {
					timeout: 10000,
				});
				setEventResponse(response.data);
				setPagination(response.data.meta);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
			window.scrollTo(0, 0);
		}
	};

	return (
		<React.Fragment>
			<div className="news-notice-section">
				<div className="container">
					<div className="row">
						<div className="col-md-9">
							<div className="content-section">
								<div className="event-section">
									<div className="main-title">
									<FormattedMessage
											id="navbar_event"
											defaultMessage={defineMessages.navbar.navbar_event}
										/>
									</div>
									{
										<Switch>
											<Route exact path="/event">
												{EventResponse.data &&
													EventResponse.data.map((event, index) => (
														<div className="row" key={event.id}>
															<div className="col-md-4">
																<Link to={`/event/${event.id}`}>
																	<div className="img-container">
																		<img
																			src={event.image}
																			alt={event.title}
																			className="img-fluid"
																		/>
																	</div>
																</Link>
															</div>
															<div className="col-md-8">
																<div className="title">
																	<Link to={`/event/${event.id}`}>
																		{event.title}
																	</Link>
																</div>
																<div
																	className="short-description"
																	dangerouslySetInnerHTML={
																		event && { __html: event.short }
																	}
																></div>
																<div className="date">
																	{event.publishedDate}
																</div>
															</div>
														</div>
													))}
												<ul class="pagination justify-content-center">
													<li class="page-item">
														<a
															class="page-link"
															onClick={() => SetPage(EventResponse.links.prev)}
														>
															Previous
														</a>
													</li>

													{pagination &&
														Array.apply(0, Array(pagination.last_page)).map(
															(x, i) => (
																<li
																	className={`page-item ${
																		pagination.current_page == i + 1
																			? "active"
																			: ""
																	}`}
																>
																	<a
																		class="page-link"
																		onClick={() => PaginateData(i + 1)}
																	>
																		{i + 1}
																	</a>
																</li>
															)
														)}

													<li class="page-item">
														<a
															class="page-link"
															onClick={() => SetPage(EventResponse.links.next)}
														>
															Next
														</a>
													</li>
												</ul>
											</Route>
											<Route path={`/event/:slug`}>
												<EventsSingle />
											</Route>
										</Switch>
									}
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="sidebar-section">
								<RecentNotice />
								<RecentNews />
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
