import React, { Suspense } from "react";
import Navbar from "./Includes/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HeaderProvider from "./Includes/HeaderContext";
import PageNotFound from "./pages/PageNotFound";
import Main from "./pages/Main";

import About from "./pages/About";
import Services from "./pages/Services";
import Vision from "./pages/Vision";
import Staff from "./pages/Staff";
import Doctor from "./pages/Doctor";
import Contact from "./pages/Contact";
import News from "./pages/News";
import Notice from "./pages/Notice";
import Events from "./pages/Events";
import Privacy from "./pages/Privacy";
import Disclaimer from "./pages/Disclaimer";
import Copyright from "./pages/Copyright";
import Appointment from "./pages/Appointment";
import Gallery from "./pages/Gallery";
import SingleGallery from "./pages/SingleGallery";
import LanguageProvider from "./Lang/LanguageProvider";
const ChairmanNote = React.lazy(()=>import('./pages/ChairmanNote'));
const Footer = React.lazy(() => import("./Includes/Footer"));

export default function Home() {
	return (
		<React.Fragment>
			<HeaderProvider>
				<LanguageProvider>
				<Router>
					<Navbar />
<Suspense fallback={<h1 className="text-center my-5">Loading...</h1>} >
					<Switch>
						<Route exact path="/">
							<Main />
						</Route>
						<Route exact path="/about">
							<About />
						</Route>
						<Route exact path="/services">
							<Services />
						</Route>
						<Route exact path="/chairman-note">
							<ChairmanNote />
						</Route>
						<Route exact path="/mission-vision">
							<Vision />
						</Route>
						<Route exact path="/appointment">
							<Appointment />
						</Route>
						<Route exact path="/administrative-staff">
							<Staff />
						</Route>
						<Route exact path="/privacy">
							<Privacy />
						</Route>
						<Route exact path="/disclaimer">
							<Disclaimer />
						</Route>
						<Route exact path="/copyright">
							<Copyright />
						</Route>
						<Route path="/doctor">
							<Doctor />
						</Route>
						<Route exact path="/gallery">
							<Gallery />
						</Route>
						<Route exact path="/gallery/:slug/:lang">
							<SingleGallery />
						</Route>
						<Route exact path="/contact">
							<Contact />
						</Route>
						<Route path="/news">
							<News />
						</Route>
						<Route path="/notice">
							<Notice />
						</Route>
						<Route path="/event">
							<Events />
						</Route>

						<Route>
							<PageNotFound />
						</Route>
					</Switch>
					</Suspense>
					<Suspense fallback={<h1>Loading</h1>}>
						<Footer />
					</Suspense>
				</Router>
				</LanguageProvider>
			</HeaderProvider>
		</React.Fragment>
	);
}
