import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import { HeaderContext } from "../Includes/HeaderContext";
import { useLocalStorage } from "../LocalStorageHook";
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/Defaultlang";

export default function Services() {
	const [ServiceResponse, setServiceResponse] = useLocalStorage(
		"ServiceResponse"
	);
	const [err, setErr] = useState();
	const { LangData } = useContext(HeaderContext);

	let getUrl =
		"https://kapilvastuhospital.p5.gov.np/api/public/api/service/" + LangData[0];

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await Axios.get(getUrl);
				setServiceResponse(response.data);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
	}, [getUrl]);

	return (
		<React.Fragment>
			<div className="servicesContainer">
				<div className="container">
					<div className="main-title">
					<FormattedMessage
								id="service_offered"
								defaultMessage={defineMessages.pages.service_offered}
							/>
						
					</div>
					{ServiceResponse &&
						ServiceResponse.data &&
						ServiceResponse.data.map((service, index) => (
							<div className="service-wrapper" key={service.id}>
								<div className="row">
									<div className="col-md-4">
										<div className="service-box">
											<div className="img-container">
												<img
													srcSet={`${service.lg} 350w,  ${service.sm} 130w`}
													src={service.sm}
													alt={service.title}
													className="img-fluid"
												/>
											</div>
											<div className="service-box-title">{service.title}</div>
										</div>
									</div>
									<div className="col-md-8">
										<div
											dangerouslySetInnerHTML={
												service && { __html: service.description }
											}
										></div>
									</div>
								</div>
							</div>
						))}
				</div>
			</div>
		</React.Fragment>
	);
}
